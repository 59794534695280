/**
#1D3866
#1C4DA1
#3399CC
#D2D7E0
#4DB748
#00CC99
*/

body {
  font-family: Helvetica, sans-serif;
}

p {
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.7rem;
  word-spacing: 0.4rem;
}

a {
  text-decoration: none;
  color: #39C;
  font-weight: 700;
}

a:hover, a:focus {
  text-decoration: underline;
  color: #1D3866;
}

h1, h2, h3, h4, h5 {
  font-family: Raleway, sans-serif;
}

a.button:hover, a.button:focus {
  text-decoration: none;
}

header.default nav.bootsnav.fixedmenu {
  border-radius: 0;
  padding: 0 20px;
}

#maxo-main {
  opacity: 0;
  transition: opacity 2s ease;
}

#maxo-main.show {
  opacity: 1;
}

.fullwidthbanner-container {
  height: 100vh;
}

.fullwidthbanner-container.show {
  display: flex !important;
}

.fullwidthbanner-container .fullwidthabanner {
  position: relative;
  width: 100%;
}

/* nav bar */
.navbar:not(.fixedmenu) ul.social li a {
  color: white;
}

.navbar .social {
  margin-top: 11px;
}

.navbar-brand {
  padding: 0;
}

.navbar-brand img {
  margin-top: 3px;
  max-width: 84px;
}

ul.social li a {
  border: none;
  color: #151515;
}

.social-facebook .fa-brands,
.social-facebook .fa-regular,
.social-facebook .fa-solid,
.social-facebook .fa-thin {
  color: #3b5998;
}

ul.social li a.social-facebook:before {
  background-color: #3b5998;
}

.social-twitter .fa-brands,
.social-twitter .fa-regular,
.social-twitter .fa-solid,
.social-twitter .fa-thin {
  color: #00aced;
}

ul.social li a.social-twitter:before {
  background-color: #151515;
}

.social-youtube .fa-brands,
.social-youtube .fa-regular,
.social-youtube .fa-solid,
.social-youtube .fa-thin {
  color: #cc181e;
}

ul.social li a.social-youtube:before {
  background-color: #cc181e;
}

ul.social li a.social-linkedin:before {
  background-color: #0e76a8;
}

.social-linkedin .fa-brands,
.social-linkedin .fa-regular,
.social-linkedin .fa-solid,
.social-linkedin .fa-thin {
  color: #0e76a8;
}

.fa-instagram:before {
  content: "\F16D";
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
  -webkit-text-fill-color: transparent;
}

ul.social li a.social-instagram:hover .fa-instagram:before,
ul.social li a.social-instagram:focus .fa-instagram:before {
  -webkit-text-fill-color: #fff;
}

ul.social li a.social-instagram:before {
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
  color: white;
}

ul.social li a:focus .fa-brands,
ul.social li a:focus .fa-regular,
ul.social li a:focus .fa-solid,
ul.social li a:focus .fa-thin,
ul.social li a:hover .fa-brands,
ul.social li a:hover .fa-regular,
ul.social li a:hover .fa-solid,
ul.social li a:hover .fa-thin {
  color: white;
}

/* animated banner captions */
.banner-container {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 0 10px;
}

#maxo-main .banner-container .button {
  line-height: 19px;
  font-size: 13px;
  margin: 5px;
}

@media (width <= 768px) {
  #maxo-main .banner-container .button {
    display: block;
  }
}

#maxo-main .banner-container .button.white {
  background-color: rgb(29 56 102 / 50%);
}

#maxo-main .banner-container > * {
  position: relative;
  transition: top 2s ease, bottom 2s ease;
}

#maxo-main .banner-container > *:first-child {
  top: -40px;
}

#maxo-main .banner-container > *:last-child {
  bottom: -40px;
}

#maxo-main.show .banner-container > *:first-child {
  top: 0;
}

#maxo-main.show .banner-container > *:last-child {
  bottom: 0;
}


/* background video player */
.background-video {
  /* background-image: radial-gradient(#1d6ab0 40%, #253d7d); */
  position: relative;
  overflow: hidden;
  height: 100%;
  color: white;
  margin-top: 60px;
}

.background-video > video {
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  width: 100%;
  margin: auto;
  object-fit: cover;
  object-position: center;
}

@media (aspect-ratio: (16 / 9)) {
  .background-video > video {
    height: 100%;
    min-width: 100%;
  }
}


@media (aspect-ratio: (16 / 9)) {
  .background-video > video {
    width: 100%;
    min-height: 100%;
  }
}


@media (aspect-ratio: (16 / 9)) {
  .background-video > video {
    min-height: 100%;
    min-width: 100%;
  }
}

/* flexbox helpers */
.row.eq-height {
  display: flex;
  flex-flow: wrap;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.row.eq-height > * {
  flex: 1 0;
}

.flex-column {
  flex-flow: column;
}

.flex-row-sm,
.flex-row-md,
.flex-row {
  flex-flow: row;
}

.flex-end {
  align-items: flex-end;
}

.flex-grow {
  flex: 1;
}

.d-flex.space-between {
  justify-content: space-between;
}

.flex-row-sm > *,
.flex-row-md > *,
.flex-row > * {
  margin: 0 10px;
}

.eq-height .icon_box,
.eq-height .panel {
  height: 100%;
}

.eq-height .panel {
  width: 100%;
}

.small {
  font-size: 0.8em;
  line-height: 1.3em;
}

.img-fluid {
  max-width: 100%;
}

@media (width <= 767px) {
  .flex-row-sm {
    flex-flow: column !important;
  }

  .flex-row-sm > * {
    width: 100%;
    margin: 5px 0;
    padding: 0 10px;
  }
}

@media (width <= 991px) {
  .flex-row-md {
    flex-flow: column !important;
  }

  .flex-row-md > * {
    width: 100%;
    margin: 5px 0;
    padding: 0 10px;
  }
}

.parallax-secction {
  height: 50vh;
  position: relative;
}

.parallax {
  clip: rect(0, auto, auto, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.parallax-bg {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  transform: translateZ(0);
  will-change: transform;
}

/* nominees */
.nominee-card {
  position: relative;
  padding-bottom: 71%;
  overflow: hidden;
  margin: 10px 0;
}

.nominee-card .overlay {
  right: 10px;
  left: 10px;
  bottom: 5px;
  padding: 1rem 2.125rem;
  opacity: .7;
}

.nominee-card .social a,
.nominee-card .overlay a {
  color: white;
}

.nominee-card .social a:hover,
.nominee-card .social a:focus,
.nominee-card .overlay a:hover,
.nominee-card .overlay a:focus {
  text-decoration: none;

}

.nominee-card .overlay .vote {
  position: absolute;
  top: -52px;
  right: 0;
  color: #fff;
  background-color: #1d3866;
  transform: translateY(50%);
  cursor: pointer;
  opacity: 0;
  padding: 10px;
}

.nominee-card .social {
  position: absolute;
  top: 10px;
  right: 10px;
  transition: all .4s ease-in;
  color: #fff;
  background-color: #1d3866;
  transform: translateX(-50%);
  opacity: 0;
  padding: 10px;
}

.nominee-card .social a {
  display: block;
}

.nominee-card .social i {
  padding: 5px 0;
}

.nominee-card img {
  transform-origin: center center;
  transition: all .8s ease;
  position: absolute;
  width: 100%;
}

.nominee-card:hover .social {
  transform: translateX(0);
  opacity: .9;
}

.nominee-card:hover .vote {
  transform: translateY(0);
  opacity: 1;
}

.nominee-card:hover img {
  transform: scale(1.15);
}

.nominee-card:hover .overlay {
  right: 10px;
  left: 10px;
  bottom: 5px;
  opacity: .9;
}

.nominee-card a {
  margin-right: 0 !important;
}

.overlay p {
  margin-bottom: 0;
}

.cbp-l-filters > .cbp-filter-item + .cbp-filter-item:before {
  content: "";
  padding: 0;
}

.cbp-l-filters {
  position: relative;
}

.cbp-l-filters > .cbp-filter-item {
  margin: 0 10px;
}

.cbp-l-filters > .cbp-filter-item:first-child {
  margin-left: 0;
}

.cbp-l-filters i {
  cursor: pointer;
  font-size: 1.5em;
}

.cbp-l-filters input {
  position: absolute;
  transition: all .4s ease;
  opacity: 0;
  left: 100%;
  right: 0;
  line-height: 2em;
  font-size: 1.8em;
  font-weight: normal;
  height: 45px;
}

.cbp-l-filters input:focus {
  left: 0;
  opacity: 1;
  width: 100%;
}

.nominees-filter.panel {
  cursor: pointer;
  transition: all .4s ease;
  box-shadow: 0 0 3px 0 rgb(130 121 121 / 60%);
}

.nominees-filter.panel h3 {
  color: #1D3866;
  margin-bottom: 10px;
}

.nominees-filter.panel p {
  font-size: 1rem;
  word-spacing: 0;
}

.nominees-filter.panel img {
  max-width: 100px;
}

.nominees-filter.panel:hover h3 {
  color: #fff;
}

.nominees-filter.panel:hover {
  background-color: #1D3866;
  color: #fff;
}

#nominees-container {
  position: relative;
}

#nominees-container .prev,
#nominees-container .next {
  font-size: 1.6em;
  transition: all .4s ease;
  opacity: .7;
}

#nominees-container .prev:hover,
#nominees-container .next:hover {
  opacity: 1;
}

#modal-message-success .modal-body,
#modal-message-error .modal-body {
  font-size: 1.6em;
  text-align: center;
}

#modal-message-success .fa-brands,
#modal-message-success .fa-regular,
#modal-message-success .fa-solid,
#modal-message-success .fa-thin,
#modal-message-error .fa-brands,
#modal-message-error .fa-regular,
#modal-message-error .fa-solid,
#modal-message-error .fa-thin {
  font-size: 3em;
  margin: 30px 0 0;
}

#modal-message-success .social .fa-brands,
#modal-message-success .social .fa-regular,
#modal-message-success .social .fa-solid,
#modal-message-success .social .fa-thin {
  font-size: 1.6em;
  margin: 20px 20px 0;
}

#modal-message-success img,
#modal-message-error img {
  margin: 30px 0 0;
  width: 300px;
  max-width: 100%;
}

#modal-message-success .message,
#modal-message-error .message {
  margin: 30px 0;
}

/* jury */
.ourteam .team_caption {
  z-index: 0;
}

.ourteam .overlay {
  background: #1D3866;
  color: white;
  inset: -30px 5px auto;
  padding: 15px 10px;
  margin-top: 100%;
  transform: translateY(-50px);
  z-index: 1;
}

.ourteam .overlay .social i {
  padding: 0 10px;
}

.ourteam:hover .overlay {
  transform: translateX(0);
  opacity: 1;
  z-index: 5;
}

.ourteam:hover .darkcolor {
  color: #515A5F;
}

/* Timeline */
.timeline {
  position: relative;
  color: #666;
}

.timeline:before {
  background-color: #bfc5c9;
  bottom: 50px;
  content: "";
  display: block;
  position: absolute;
  left: 49px;
  top: 23px;
  width: 1px;
  border-left: 1px solid #20325f;
}

.timeline-header {
  border-radius: 3px;
  clear: both;
  margin-bottom: 50px;
  margin-top: 50px;
  position: relative;
}

.timeline-header .timeline-header-title {
  display: inline-block;
  text-align: center;
  padding: 7px 15px;
  min-width: 100px;
}

.timeline .timeline-header:first-child {
  margin-bottom: 30px;
  margin-top: 15px;
}

.timeline-stat {
  width: 100px;
  float: left;
  text-align: center;
  padding-bottom: 15px;
}

.timeline-entry {
  margin-bottom: 25px;
  margin-top: 5px;
  position: relative;
  clear: both;
}

.timeline-entry-inner {
  position: relative;
}

.timeline-time {
  display: inline-block;
  padding: 2px 3px;
  background-color: #ecf0f5;
  color: #20325f;
  font-size: .85em;
  max-width: 70px;
}

.timeline-icon {
  border-radius: 50%;
  display: block;
  margin: 18px auto 0;
  width: 15px;
  height: 15px;
  line-height: 10px;
  text-align: center;
  padding: 0;
  background-color: #333;
  border: 1px solid #20325f;
}

.timeline-icon > i {
  font-size: 30px;
  line-height: 30px;
  vertical-align: 0.1em;
}

.timeline-icon img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  vertical-align: top;
}

.timeline-icon:empty {
  height: 12px;
  width: 12px;
  margin-top: 20px;
  border: 2px solid #20325f;
}

.timeline-label {
  background-color: #39C;
  color: #fff;
  border-radius: 3px;
  margin-left: 85px;
  padding: 15px;
  position: relative;
  min-height: 50px;
  font-size: 1.2em;
  margin-right: 30px;
}

.timeline-label:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  top: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 9px solid #39C;
  margin: 15px 0 0 -9px;
}

@media (width >= 992px) {
  .two-column.timeline {
    text-align: center;
    display: flex;
    flex-flow: row;
    margin: 0 7%;
  }

  .two-column.timeline:before {
    left: 10%;
    right: 10%;
    top: 50%;
    width: auto;
    height: 1px;
  }

  .two-column.timeline .timeline-entry {
    width: 20%;
    height: auto;
    margin: 0;
    text-align: left;
    display: flex;
    flex-flow: column;
    justify-content: initial;
  }

  .two-column.timeline .timeline-stat {
    left: 0;
    right: 0;
    top: 50%;
    width: auto;
    position: absolute;
    margin-top: -7px;
    padding: 0;
  }

  .two-column.timeline .timeline-icon {
    margin-top: 0;
  }

  .two-column.timeline .timeline-label {
    margin-left: 0;
    margin-left: -40%;
    margin-right: -40%;
    text-align: center;
  }

  .two-column.timeline .timeline-entry:nth-child(odd) {
    flex-flow: column-reverse;
  }

  .two-column.timeline .timeline-entry:nth-child(odd) .timeline-label {
    margin-bottom: 210px;
  }

  .two-column.timeline .timeline-entry:nth-child(odd) .timeline-label:after {
    left: 50%;
    transform: translateX(-50%);
    top: auto;
    bottom: -9px;
    border-right: 9px solid transparent;
    border-left: 9px solid transparent;
    border-bottom: 0 solid transparent;
    border-top: 9px solid #39C;
    margin: 0;
  }

  .two-column.timeline .timeline-header {
    text-align: center;
  }

  .two-column.timeline .timeline-entry:nth-child(even) .timeline-label {
    margin-top: 210px;
  }

  .two-column.timeline .timeline-entry:nth-child(even) .timeline-label:after {
    left: 50%;
    transform: translateX(-50%);
    bottom: auto;
    top: -9px;
    border-right: 9px solid transparent;
    border-top: 0 solid transparent;
    border-left: 9px solid transparent;
    border-bottom: 9px solid #39C;
    margin: 0;
  }
}

.icon_wrap .icon_box h2 {
  font-size: 55px;
}

.icon_wrap .icon_box svg {
  width: 50px;
  height: 50px;
  fill: #1d3866;
}

.icon_wrap .icon_box:hover svg {
  fill: white;
}

.embed-video {
  position: relative;
  padding-bottom: 56.1702%;
}

.embed-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

video.video-player {
  width: 100%;
}

.bgbrand-right {
  background-image: url("../images/background-1.png");
  background-position: top -52px right -143px;
  background-size: 510px;
  background-repeat: no-repeat;
}

.bgbrand-left {
  background-image: url("../images/background-2.png");
  background-position: top -533px left -29px;
  background-size: 510px;
  background-repeat: no-repeat;
}


/* form */
input[type="file"] {
  box-shadow: none;
}

input.check:invalid,
.input-radio-buttons input.check:invalid ~ label,
.form-control.check:invalid {
  box-shadow: 0 0 2px 1px rgba(255 0 0 / 75%);
}

input.check:valid,
.form-control.check:valid {
  box-shadow: 0 0 2px 1px rgba(77 183 72 / 75%);
}

.input-radio-buttons label {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  color: #1d3866;
  background: #fff;
  border: 1px solid #1d3866;
  padding: 10px 5px;
  margin: 0;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  font-size: 1.1em;
}

@media (width <= 767px) {
  .input-radio-buttons label img {
    display: none;
  }
}

.input-radio-buttons input {
  display: none;
}

.input-radio-buttons input:checked ~ label,
.input-radio-buttons label:hover {
  color: #fff;
  background: #1d3866;
  border: 1px solid #1d3866;
}

.input-radio-buttons img {
  width: 50px;
}

form label,
form .form-label {
  font-size: 1.4em;
  font-weight: bold;
  color: #1d3866;
}

.help-block {
  font-size: 1.1em;
  word-spacing: 0.2em;
}

.help-block.text-danger {
  color: #a94442;
}

.button.disabled {
  pointer-events: none;
  background-color: #ccc;
}

/* loader */
.spin-loader {
  min-height: 200px;
  position: relative;
}

.spin-loader:before {
  content: '';
  position: absolute;
  display: block;
  inset: 0;
  background-color: rgba(245 245 245 / 70%);
  z-index: 1;
}

.spin-loader:after {
  content: '';
  position: absolute;
  width: 34px;
  height: 34px;
  inset: 0;
  margin: auto;
  animation: rotate .8s infinite linear;
  border-left: 3px solid rgba(114 144 182 / 15%);
  border-right: 3px solid rgba(114 144 182 / 15%);
  border-bottom: 3px solid rgba(114 144 182 / 15%);
  border-top: 3px solid #39C;
  border-radius: 100%;
  z-index: 2;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.slide-left,
.slide-right {
  position: relative;
  transition: left .4s ease;
}

.slide-left {
  left: 100%;
}

.slide-right {
  left: -100%;
}

.slide-left.in,
.slide-right.in {
  left: 0;
}

.slide-left.out,
.slide-right.out {
  position: absolute;
  top: 0;
  width: 100%;
}

.slide-left.out {
  left: -100%;
}

.slide-right.out {
  left: 100%;
}