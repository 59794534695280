/* Table Of Contents
01 body
02 header
    nav
03 Revolution
04 Swiper
05 overlay
06 work-gallery
07 page-header
08 Features
09 ourteam
10 ourblog
11 pricing
12 footer
*/


/* font-family: 'Roboto', sans-serif;

font-family: 'Raleway', sans-serif; */


/* Global Styling */
body {
    padding: 0;
    margin: 0;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    color: #666;
    position: relative;
    overflow-x: hidden;
}

html {
    height: 100%;
    width: 100%;
    font-size: 16px;
}

ol, ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a, a:hover, a:focus {
    outline: none;
    text-decoration: none;
    color: inherit;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

h1, .h1 {
    font-size: 4.063em;
}

h2, .h2 {
    font-size: 3rem;
}

h3, .h3 {
    font-size: 1.5rem;
}

h4, .h4 {
    font-size: 1.25rem;
}

h5, .h5 {
    font-size: 1.125rem;
}

h6, .h6 {
    font-size: 1rem;
}

p {
    font-size: 0.9375rem;
    font-weight: 300;
    line-height: 1.34rem;
    word-spacing: 0.2rem;
}

p.title {
    font-size: 1rem;
}

.container {
    width: 1170px;
    max-width: 100%;
}

.container, .container-fluid {
    padding-left: 10px;
    padding-right: 10px;
}

/* Share common font family */
h2, h3, .testimonial .testimonial_slide h6,
aside .widget h4 {
    font-weight: 400;
}


/* Helper Classes & Shorcodes */
.padding {
    padding: 7.5rem 0;
}

.padding_top {
    padding-top: 7.5rem;
}

.padding_bottom {
    padding-bottom: 7.5rem;
}

.padding_half {
    padding: 3.75rem 0;
}

.padding_bottom_half {
    padding-bottom: 3.75rem;
}

.padding_top_half {
    padding-top: 3.75rem;
}

.margin_bottom {
    margin-bottom: 7.5rem;
}

.margin_top {
    margin-top: 7.5rem;
}

.heading_space {
    margin-bottom: 3.75rem;
}

.bottom5 {
    margin-bottom: 5px;
}

.bottom10 {
    margin-bottom: 10px;
}

.top10 {
    margin-top: 10px;
}

.bottom15 {
    margin-bottom: 15px;
}

.top15 {
    margin-top: 15px;
}

.top20 {
    margin-top: 20px;
}

.bottom20 {
    margin-bottom: 20px;
}

.bottom25 {
    margin-bottom: 25px;
}

.top25 {
    margin-top: 25px;
}

.bottom30 {
    margin-bottom: 30px;
}

.top30 {
    margin-top: 30px;
}

.bottom35 {
    margin-bottom: 35px;
}

.top40 {
    margin-top: 40px;
}

.bottom40 {
    margin-bottom: 40px;
}

.bottom45 {
    margin-bottom: 45px;
}

.bottom0 {
    margin-bottom: 0;
}

.nomargin {
    margin: 0;
}

.bglight {
    background: #f5f5f5;
}

.bgdark {
    background: #f2c900;
}

.bgdefault {
    background: #515A5F;
}

.whitecolor {
    color: #fff;
}

.darkcolor {
    color: #515A5F;
}

.colorblack {
    color: #232323;
}

footer .row > div {
    z-index: 4;
}

/* img wrap */
.image,
.cbp-item {
    overflow: hidden;
}

.image img {
    width: 100%;
    display: block;
}

.image,
.image img {
    position: relative;
}

.block_image > img,
.image img,
.cbp-item img,
.latest_updates .updatephoto > img {
    width: 100%;
    transition: all .8s ease;
    transform-origin: center center;
}

.feature_block:hover .block_image > img,
.ourteam:hover .image img,
.ourblog:hover .image img,
.cbp-item:hover img,
.latest_updates:hover .updatephoto img,
.image:hover img {
    transform: scale(1.15);
}

/* to align elements */
.centered,
.vertical {
    display: block;
    position: absolute;
    top: 50%;
    width: 100%;
}

.vertical {
    transform: translateY(-50%);
}

.centered {
    left: 50%;
    transform: translate(-50%, -50%);
}

/* sidebar search */
.widget_search .form-control {
    border: 2px #e8ecf0 solid;
    border-right: none;
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    height: 42px;
    position: relative;
}

.widget_search .input-group-addon {
    background: transparent;
    border: 2px #e8ecf0 solid;
    border-left: none;
    border-radius: 0;
    color: #f2c900;
    cursor: pointer;
    font-size: 19px;
    padding: 0 12px;
}

.widget .media-body > a {
    font-weight: bold;
    color: #515A5F;
    line-height: 1.5rem;
}


/* Categories */
.category li {
    border-bottom: 1px solid #e8ecf0;
    display: block;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.category li a {
    color: #666;
}

.category li a > span {
    float: right;
}

.category li a:hover, .category li a:focus,
.widget .media-body > a:hover {
    color: #f2c900;
}


/* Tags */
ul.web_tags li,
.pager,
ul.social li,
ul.social_simple li {
    display: inline-block;
}

ul.web_tags li a {
    background: #f2c900;
    color: #fff;
    display: block;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 8px;
    margin: 2px 17px 8px 1px;
    padding: 6px 25px 6px 15px;
    position: relative;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    height: 20px;
}

ul.web_tags li a:before {
    background-color: inherit;
    content: "";
    height: 10px;
    position: absolute;
    right: -5px;
    top: 5px;
    transform: skew(-45deg, 45deg);
    width: 10px;
    z-index: -1;
}

ul.web_tags li a:after {
    background: #fff;
    border-radius: 50%;
    content: "";
    height: 6px;
    margin-top: -3px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 6px;
}

/* Pagination */
.pager {
    border-bottom: 2px solid #ebebeb;
    margin: 0;
    text-align: left;
}

.pager li > a {
    background: rgb(0 0 0 / 0%);
    border: medium none;
    border-radius: 0;
    color: #999;
    font-weight: bold;
    line-height: 1;
    padding: 0 10px 20px;
    position: relative;
    text-align: center;
}

.pager li > a:before {
    background: #f2c900;
    content: "";
    height: 3px;
    width: 0;
    left: 0;
    bottom: -1px;
    position: absolute;
}

.pager li > a:hover:before,
.pager li.active > a:before {
    width: 100%;
}

.pager li > a:hover,
.pager li > a:focus,
.pager li.active > a {
    color: #f2c900;
    background: transparent;
}

/* Comment Profile */
.eny_profile {
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 30px;
    position: relative;
}

.eny_profile .profile_photo {
    border-radius: 100%;
    height: 60px;
    width: 60px;
}

.eny_profile .profile_photo > img {
    border-radius: 100%;
}

.eny_profile .profile_text {
    padding-left: 20px;
}

.eny_profile .profile_photo,
.eny_profile .profile_text {
    display: table-cell;
    vertical-align: top;
}

/* Social Icons */
ul.social li a {
    display: block;
    height: 34px;
    width: 34px;
    position: relative;
    border: 1px solid #bdbdbd;
    color: #151515;
    margin: 1px;
    text-align: center;
    line-height: 34px;
    font-size: 15px;
    border-radius: 100%;
    z-index: 2;
}

ul.social li a:before {
    content: "";
    background: #515A5F;
    border-radius: 100%;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    opacity: 0;
    transform: scale(0);
    z-index: -1;
}

ul.social li a:hover:before,
ul.social li a:focus:before {
    opacity: 1;
    transform: scale(1);
}

ul.social li a:hover,
ul.social li a:focus {
    color: #fff;
}

ul.social_simple li a {
    display: block;
    font-size: 17px;
    line-height: 1;
    margin: 7px 5px;
}

ul.social_vertical li a {
    display: block;
    font-size: 20px;
    color: #515A5F;
}

ul.social.white li a {
    border: 1px solid #fff;
}

ul.social_simple.white li a,
ul.social.white li a {
    color: #fff;
}

ul.social_simple li a:hover,
ul.social_simple li a:focus,
ul.social_vertical li a:hover,
ul.social_vertical li a:focus {
    color: #f2c900;
}


.back-top {
    background: #515A5F;
    color: #fff;
    bottom: 30px;
    box-shadow: 0 0 50px 0 rgb(68 48 136 / 50%);
    font-size: 20px;
    height: 38px;
    line-height: 34px;
    position: fixed;
    text-align: center;
    width: 38px;
    z-index: 1600;
    opacity: 0;
    right: 2rem;
    border-radius: 100%;
    transform: scale(0);
}

.back-top-visible {
    opacity: 1;
    transform: scale(1);
}

.back-top:hover, .back-top:focus {
    color: #fff;
}


/* Helper Classes ends */
.category li a,
.widget .media-body > a,
.pager li > a,
.readmore, .button,
.updates .latest_updates .update_text p {
    font-size: 0.875rem;
}

/* Buttons */
.button {
    display: inline-block;
    padding: 0.75rem 2.875rem;
    font-weight: bold;
    position: relative;
    vertical-align: middle;
    z-index: 1;
    border: 2px solid transparent;
    border-radius: 4px;
    text-transform: uppercase;
}

.button.dark, .button.light {
    color: #fff;
}

.button.dark {
    background: #515A5F;
}

.button.light {
    background: #f2c900;
}

.button.white {
    background: #fff;
    border: 2px solid #fff;
    color: #515A5F;
}

/* hover */
.button:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    z-index: -1;
    opacity: 0;
}

.button.light:before,
.button.white:before {
    background: #515A5F;
}

.button.light:hover,
.button.light:focus,
.button.white:hover,
.button.white:focus {
    border: 2px solid #515A5F;
}

.button.dark:before {
    background: #f2c900;
}

.button.dark:hover,
.button.dark:focus {
    border: 2px solid #f2c900;
}

#maxo-main .button.light:before {
    background: #515A5F;
}

#maxo-main .button.light:hover,
#maxo-main .button.light:focus {
    color: #fff;
    border: 2px solid #515A5F;
}

.button:hover:before,
.button:focus:before {
    width: 100%;
    opacity: 1;
}

.button:hover,
.button:focus {
    color: #fff;
}


/* Some Short Codes or helper classes */


/* transitions */
ul.social li a:before,
nav.navbar.navbar-default,
nav.navbar.bootsnav ul.nav > li > a:before,
.navbar-brand > img,
nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li > a:before,
.overlay-menu, .overlay-menu ul.full-nav li a:before,
.menu-icon span, .menu-icon span:before, .menu-icon span:after,
.tp-bullet, .swiper-pagination-bullet,
.swiper-button-next, .swiper-button-prev,
.cbp-l-filters .cbp-filter-item span:after,
.icon_wrap .icon_box, .icon_wrap .icon_box hr,
.back-top, .back-top-visible,
a, .pager li > a:before, .button:before,
.ourteam .social_vertical,
.overlay, .pricing_item {
    transition: all .4s ease-in;
}


/* ------ Headers Starts ------ */
nav.navbar.navbar-default {
    border-bottom: none;
    box-shadow: 0 0;
    padding: 10px 0;
}

header.default nav.navbar.bootsnav.navbar-fixed {
    background: transparent;
}

header.default .bootsnav.fixedmenu {
    background: #fff !important;
    z-index: 11;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    animation-name: animationFade;
    animation-duration: 1s;
    animation-fill-mode: both;
    box-shadow: 0 2px 8px -1px rgb(0 0 0 / 20%);
    padding: 0;
}

@keyframes animationFade { /* stylelint-disable-line keyframes-name-pattern */
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

@keyframes animationFade { /* stylelint-disable-line keyframes-name-pattern */
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

@keyframes animationFade { /* stylelint-disable-line keyframes-name-pattern */
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

@keyframes animationFade { /* stylelint-disable-line keyframes-name-pattern */
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.navbar.navbar-empty {
    position: absolute;
    background: transparent;
    top: 0;
    z-index: 5;
    width: 100%;
}

nav.navbar.bootsnav .navbar-toggle {
    background-color: transparent !important;
    float: right;
    margin-right: 0;
    margin-top: 12px;
    color: #515A5F;
}

.navbar-brand {
    height: auto;
    padding: 11px 15px 11px 0;
    margin-left: 0 !important;
}

.navbar-brand > img {
    width: 110px;
}

.bootsnav.fixedmenu .navbar-brand > img,
nav.navbar.bootsnav.navbar-fixed.fixedmenu .navbar-brand > img {
    width: 100px;
}

header.default nav.navbar.bootsnav.navbar-fixed .logo-display,
header.default nav.navbar.bootsnav.navbar-fixed.fixedmenu .logo-scrolled {
    display: inline-block;
}

header.default nav.navbar.bootsnav.navbar-fixed .logo-scrolled,
header.default nav.navbar.bootsnav.navbar-fixed.fixedmenu .logo-display {
    display: none;
}

nav.bootsnav.brand-center .navbar-collapse .col-half.left {
    float: left;
    width: auto;
}

nav.bootsnav.brand-center .navbar-collapse .col-half.right {
    float: right;
    width: auto;
}

nav.navbar.bootsnav ul.nav > li {
    margin: 0 2rem;
}

nav.navbar.bootsnav.brand-center ul.nav > li:first-child {
    margin-left: 0;
}

nav.navbar.bootsnav.brand-center ul.nav > li:last-child {
    margin-right: 0;
}

nav.navbar.bootsnav ul.nav > li > a {
    font-weight: 500;
    padding: 19px 0;
    font-size: 14px;
    text-transform: uppercase;
    border: none;
}

header.default nav.navbar.bootsnav.navbar-fixed ul.nav > li > a {
    color: #fff;
}

header.default nav.navbar.bootsnav ul.nav > li > a,
header.default .bootsnav.fixedmenu ul.nav > li > a,
header.default nav.navbar.bootsnav.navbar-fixed.fixedmenu ul.nav > li > a {
    color: #515A5F;
}

nav.navbar.bootsnav ul.nav > li > a:before,
.cbp-l-filters .cbp-filter-item > span:after,
.overlay-menu ul.full-nav li a:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: #f2c900;
    transform: scale3d(0, 1, 1);
}

nav.navbar.bootsnav ul.nav > li > a:hover:before,
nav.navbar.bootsnav ul.nav > li.active a:before,
.cbp-l-filters .cbp-filter-item span:hover:after,
.cbp-l-filters .cbp-filter-item-active span:after,
.overlay-menu ul.full-nav li a:hover:before,
.overlay-menu ul.full-nav li.active a:before {
    transform: scale3d(1, 1, 1);
}


/* OVERLAY Menu */
.menu-icon {
    background: transparent;
    width: 30px;
    height: 24px;
    margin: 15px 0 0 1.75rem;
    position: relative;
    cursor: pointer;
    transition: background 0.5s;
    float: right;
    z-index: 16;
}

.menu-icon span,
.menu-icon span:before,
.menu-icon span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 2px;
    width: 22px;
    background: #515A5F;
    position: absolute;
    left: 15%;
    top: 50%;
    display: block;
    content: '';
}

header.default nav.navbar.bootsnav.navbar-fixed.fixedmenu .menu-icon span,
header.default nav.navbar.bootsnav.navbar-fixed.fixedmenu .menu-icon span:before,
header.default nav.navbar.bootsnav.navbar-fixed.fixedmenu .menu-icon span:after {
    background: #515A5F;
}

header.default nav.navbar.bootsnav.navbar-fixed .menu-icon span,
header.default nav.navbar.bootsnav.navbar-fixed .menu-icon span:before,
header.default nav.navbar.bootsnav.navbar-fixed .menu-icon span:after {
    background: #fff;
}

.navbar.navbar-empty .menu-icon span,
.navbar.navbar-empty .menu-icon span:before,
.navbar.navbar-empty .menu-icon span:after {
    background: #fff;
}

.menu-icon span:before {
    left: 0;
    top: -8px;
}

.menu-icon span:after {
    left: 0;
    top: 8px;
}

.menu-icon.active {
    position: fixed;
    top: 1.5rem;
    right: 1.5rem;
}

.menu-icon.active span {
    background: transparent;
}

.menu-icon.active span:before,
.menu-icon.active span:after {
    top: 0;
}

.menu-icon.active span:before {
    transform: rotate(135deg);
}

.menu-icon.active span:after {
    transform: rotate(-135deg);
}

.overlay-menu {
    background: #fff;
    color: #515A5F;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden auto;
    width: 100%;
    padding: 3.75rem 0;
    opacity: 0;
    text-align: center;
    transform: translateY(100px);
    z-index: -1;
}

.overlay-menu.open {
    opacity: 1;
    transform: translateY(0);
    z-index: 15;
}

.overlay-menu .logo-full {
    display: inline-block;
    width: 120px;
}

.overlay-menu .logo-full > img {
    width: 100%;
}

.overlay-menu .centered {
    width: 620px;
    max-width: 99%;
}

.overlay-menu ul.full-nav li {
    transition: all 0.7s;
    transform: translate3d(0, -10px, 0);
    opacity: 0;
    display: inline-block;
    width: 49%;
    position: relative;
    padding: 1.5rem 2rem;
    text-align: left;
    background: #f5f5f5;
    margin-bottom: 5px;
}

.overlay-menu.open ul.full-nav li {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

.overlay-menu.open ul.full-nav li:first-child {
    transition-delay: .1s;
}

.overlay-menu.open ul.full-nav li:nth-child(2) {
    transition-delay: .2s;
}

.overlay-menu.open ul.full-nav li:nth-child(3) {
    transition-delay: .3s;
}

.overlay-menu.open ul.full-nav li:nth-child(4) {
    transition-delay: .4s;
}

.overlay-menu.open ul.full-nav li:nth-child(5) {
    transition-delay: .5s;
}

.overlay-menu.open ul.full-nav li:nth-child(6) {
    transition-delay: .6s;
}

.overlay-menu.open ul.full-nav li:nth-child(7) {
    transition-delay: .7s;
}

.overlay-menu.open ul.full-nav li:nth-child(8) {
    transition-delay: .8s;
}

.overlay-menu.open ul.full-nav li:nth-child(9) {
    transition-delay: .9s;
}

.overlay-menu ul.full-nav li > span {
    color: #515A5F;
    font-size: 14px;
    font-weight: 300;
    vertical-align: middle;
    margin-right: 10px;
    display: inline;
}

.overlay-menu ul.full-nav li a {
    position: relative;
    font-size: 1.5rem;
    color: #515A5F;
    overflow: hidden;
    padding: 0.8rem 0;
    font-weight: 500;
    text-transform: uppercase;
}


/* -----  Headers Ends ------ */


/* ------ main Slider Starts ------ */

#maxo-main h1 {
    font-family: Raleway, sans-serif;
    font-size: 3rem;
    font-weight: 100;
}

#maxo-main h1 > strong {
    font-weight: 500;
    font-size: inherit;
}

#maxo-main h1 span {
    display: block;
    font-size: 2.25rem;
    font-style: normal;
}

#maxo-main a {
    margin-right: 5px;
}

/* arrows Slider */
.tparrows {
    display: inline-block;
    background: transparent !important;
}

.tparrows.tp-leftarrow:before {
    /* content: url("../images/prev.png"); */
}

.tparrows.tp-rightarrow:before {
    /* content: url("../images/next.png"); */
}


/* Bullets
Swiper & Revotion */
.tp-bullet {
    margin: 5px !important;
    position: absolute;
}

.tp-bullet,
.swiper-pagination-bullet {
    border-radius: 100%;
    width: 12px;
    height: 12px;
    overflow: hidden;
    background: #515A5F;
}

.tp-bullet:hover,
.tp-bullet.selected,
.swiper-pagination-bullet:hover,
.swiper-pagination-bullet-active {
    background: #F2C900;
}

/* ------ main Slider ends ------ */

.left-padding {
    padding-left: 9% !important;
    padding-right: 9% !important;
}


/* ------ Swiper Slider ends ------ */


/* ------ POrtfolio starts ------ */
.overlay {
    background: rgb(242 201 0 / 80%);
    position: absolute;
    left: -15px;
    bottom: 15px;
    padding: 4rem 3.125rem;
    opacity: 0;
}

.cbp-item:hover .overlay {
    right: 15px;
    left: 15px;
    opacity: 1;
}

.overlay .likeus {
    background: rgb(242 201 0 / 80%);
    font-size: 14px;
    padding: 11px 14px;
    position: absolute;
    right: 0;
    text-align: center;
    top: -57px;
    opacity: 0;
}

.cbp-item:hover .likeus {
    opacity: 1;
}

.overlay h4 {
    font-size: 1rem;
    word-break: break-word;
}

.overlay p {
    font-weight: bold;
}

.work_headings {
    background: #fff;
    display: inline-table;
    padding: 0 15px 30px 0;
    position: relative;
    z-index: 100;
}

.cbp-l-filters > .cbp-filter-item {
    margin: 0 7px;
}

.cbp-l-filters .cbp-filter-item {
    display: inline-block;
    cursor: pointer;
}

.cbp-l-filters .cbp-filter-item-active {
    cursor: default;
}

.cbp-l-filters > .cbp-filter-item + .cbp-filter-item:before {
    content: "-";
    padding: 0 0.8125rem;
}

.cbp-l-filters .cbp-filter-item span {
    color: #515A5F;
    display: inline-block;
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}

.cbp-l-filters .cbp-filter-item > span {
    padding-bottom: 17px;
}

.cbp-l-filters .cbp-filter-item span.text-lowercase {
    font-size: 1.3em;
    text-transform: lowercase;
    font-weight: initial;
}

.cbp-item .text_wrap {
    color: #202020;
    height: 250px;
    overflow: hidden;
}

.cbp-item .text_wrap h1,
.cbp-item .text_wrap h5 {
    font-weight: bold;
}

.cbp-item .text_wrap p {
    color: transparent;
}

.text_wrap {
    position: absolute;
    inset: 0;
}

/* ------ Portfolio ends ------ */


/* ------ Page Header starts ------ */
.page-header {
    border: none;
    margin: 0;
    padding-bottom: 0;
    background-size: contain;
    background-attachment: fixed;
    position: relative;
}

.page-header h1 {
    font-size: 2.25rem;
    font-weight: bold;
}

.breadcrumb {
    background: transparent;
    padding: 0;
}

.breadcrumb li {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}

.breadcrumb li,
.breadcrumb li a {
    color: #fff;
}

.breadcrumb li a:hover {
    color: #f2c900;
}

/* ------ Page Header ends ------ */


/* ------ Parallax  & Bg starts ------ */
.bg {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.bg .bghalf {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 50%;
}

.bg .bghalf.imgone {
    left: 0;
    background-position: left center;
}

.bg .bghalf.imgtwo {
    right: 0;
    background-position: right center;
}

.bg .bghalf.imgone, .bg .bghalf.imgtwo {
    background-attachment: fixed;
    background-size: contain;
}

.parallax,
#text-rotate {
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    position: relative;
}

.bg-transparent {
    background: rgb(255 255 255 / 55%);
    width: 100%;
}

.testimonial.center .testimonial_slide .photo {
    display: inline-block;
}

.testimonial.center .testimonial_slide .signature {
    display: block;
    padding: 0;
}

.bg .left {
    padding-left: 2rem;
    padding-right: 2rem;
}

.bg .right {
    padding-left: 2rem;
    padding-right: 2rem;
}

.parallax.parallax-bg h2 {
    font-size: 2rem;
    font-weight: 300;
    line-height: 3rem;
}

.parallax.parallax-bg h2 i {
    font-size: 1.125rem;
    color: #9c9c9c;
}

.parallax.parallax-bg p {
    color: #f2c900;
}

.text-rotator .swiper-slide,
.text-rotator .swiper-slide .swipe-rotate {
    transition: all .4s ease;
}

.text-rotator .swiper-slide .swipe-rotate {
    opacity: 0 !important;
}

.text-rotator .swiper-slide.swiper-slide-active .swipe-rotate {
    opacity: 1 !important;
}

.text-rotator h1 {
    font-family: Raleway, sans-serif;
    font-size: 3rem;
    font-weight: 100;
}

.text-rotator h1 > strong {
    font-size: inherit;
    font-weight: 500;
}

.text-rotator p {
    font-size: 1.125rem;
}

#text-rotate .swiper-pagination {
    position: absolute;
    margin-bottom: 25px;
    left: 0;
    right: 0;
}

/* ------ Parallax starts ------ */


/* ------ Features ------ */

.icon_wrap .icon_box {
    position: relative;
    padding: 40px 20px;
    box-shadow: 0 0 3px 0 rgb(130 121 121 / 60%);
    cursor: default !important;
}

.icon_wrap .icon_box.whitebox {
    padding: 80px 30px;
    border-radius: 0;
}

.icon_wrap.noshadow .icon_box {
    box-shadow: none;
}

.icon_wrap .icon_box i {
    display: inline-block;
    font-size: 50px;
    color: #f2c900;
    margin-bottom: 20px;
    position: relative;
}

.icon_wrap .icon_box.whitebox i {
    color: #666;
}

.icon_wrap .icon_box:hover i {
    animation-name: fadeInUp;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-duration: .7s;
}

.icon_wrap .icon_box hr {
    width: 45px;
    height: 3px;
    background: #666;
    margin: 0 auto 20px;
    border: none;
}

.icon_wrap .icon_box:hover i, .icon_wrap .icon_box:hover p {
    color: #fff;
}

.icon_wrap .icon_box:hover hr {
    background: #fff;
    width: 50px;
}

.icon_wrap .icon_box:hover {
    background: #f2c900;
    color: #fff;
    cursor: pointer;
}

/* ------ Features ends ------ */


/* ------ Our Team ------ */
.ourteam .team_caption {
    padding: 26px 10px;
    position: relative;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    z-index: 1;
    background: #f5f5f5;
}

.team_caption .overlay {
    background: #f2c900;
    height: 100%;
    left: 0;
    padding: 0;
    top: 0;
    width: 0;
    z-index: -1;
}

.ourteam:hover .team_caption .overlay {
    opacity: 1;
    width: 100%;
    z-index: 5;
}

.ourteam:hover .darkcolor {
    color: #fff;
}

.ourteam .team_caption p {
    font-size: 0.75rem;
    font-weight: normal;
    margin-bottom: 0;
}

.ourteam .social_vertical {
    background: #fff;
    padding: 10px;
    position: absolute;
    z-index: 1;
    opacity: 0;
    text-align: center;
    right: -10px;
    top: 10px;
    transform: scaleX(0);
}

.ourteam:hover .social_vertical {
    opacity: 1;
    right: 10px;
    transform: scaleX(1);
}


@media screen and (width <= 1200px) {
    html {
        font-size: 13px;
    }

    /* Header */
    nav.navbar.bootsnav ul.nav > li {
        margin: 0 1.25rem;
    }

    nav.navbar.bootsnav ul.nav > li a {
        font-size: 13px;
    }

    /* Blog */
    #ourblog h2 {
        font-size: 2rem;
    }
}

@media screen and (width <= 1024px) {
    html {
        font-size: 13px;
    }

}

@media screen and (width <= 1023px) {
    /* nav.navbar.bootsnav.no-background .menu-icon span,
     nav.navbar.bootsnav.no-background .menu-icon span:before,
     nav.navbar.bootsnav.no-background .menu-icon span:after{
         background: #515A5F;
     } */

}

@media screen and (width >= 993px) and (width <= 1023px) {
    /* header.default nav.navbar.bootsnav.navbar-fixed .logo.logo-display{
            display: none !important;
    } */

}

@media screen and (width <= 992px) {
    h1, .h1 {
        font-size: 3.063em;
    }

    h2, .h2 {
        font-size: 2rem;
    }

    h3, .h3 {
        font-size: 1.375rem;
    }

    h4, .h4 {
        font-size: 1.125rem;
    }

    h5, .h5 {
        font-size: 1rem;
    }

    h6, .h6 {
        font-size: 0.9375rem;
    }

    /* Header */
    nav.navbar.navbar-default {
        padding: 0;
    }

    nav.bootsnav.brand-center .navbar-collapse .col-half.left,
    nav.bootsnav.brand-center .navbar-collapse .col-half.right {
        float: none;
        margin: 0;
    }

    .navbar-brand > img,
    .nav.navbar.bootsnav .navbar-brand > img,
    .nav.navbar.bootsnav.fixedmenu .navbar-brand > img,
    nav.navbar.bootsnav.navbar-fixed.fixedmenu .navbar-brand > img {
        width: 80px !important;
    }

    header.default nav.navbar.bootsnav.navbar-fixed {
        background: #fff;
    }

    nav.navbar.bootsnav ul.nav > li {
        margin: 0;
    }

    header.default nav.navbar.bootsnav.navbar-fixed ul.nav > li > a {
        color: #515A5F;
    }

    header.default nav.navbar.bootsnav.navbar-fixed .menu-icon span,
    header.default nav.navbar.bootsnav.navbar-fixed .menu-icon span:before,
    header.default nav.navbar.bootsnav.navbar-fixed .menu-icon span:after {
        background: #515A5F;
    }

    /* BG & Parallax */
    .bg .bghalf {
        position: relative;
    }

    /* JURY */
    #jury .row.eq-height {
        flex: none;
        display: block;
    }
}

@media screen and (width <= 767px) {
    /* Header */

    /* .overlay-menu{
        overflow-y: scroll;
    } */
    .overlay-menu .heading_space {
        margin-bottom: 1.5rem;
    }

    .overlay-menu .logo-full {
        width: 90px;
        margin-top: 0.5rem;
    }

    .overlay-menu ul.full-nav li {
        padding: 1rem;
    }

    .overlay-menu ul.full-nav li > span {
        font-size: 12px;
    }

    .overlay-menu ul.full-nav li a {
        font-size: 1.125rem;
    }

    /* Header */

    /* BG */
    #work .container > .row,
    #work .container > .row .text-right {
        text-align: center;
    }

    .page-header {
        background-size: cover;
        background-position: center center;
    }

    .text-rotator h1 {
        font-size: 2.5rem;
    }

    /* Footer */
    footer, footer .text-right {
        text-align: center;
    }

}

@media screen and (width <= 370px) {
    .overlay-menu ul.full-nav li > span {
        display: none;
    }

    .overlay-menu ul.full-nav li a {
        font-size: 1rem;
    }
}

.row {
    margin-right: -10px;
    margin-left: -10px;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-right: 10px;
    padding-left: 10px;
}

.right-to-left {
    float: right;
    direction: rtl;
}

@media screen and (width <= 767px) {
    .text-xs-center {
        text-align: center;
    }
}

p.nominee-voting-closed {
    border: 1px solid #39c;
    padding: 10px;
}

#winners-modal p {
    font-size: 1.2rem;
}

.col-centered {
    float: none;
    margin: 0 auto;
}

@media screen and (width >= 768px) {
    .col-sm-offset-1-half {
        margin-left: 12.5%;
    }
}

@media (width >= 992px) {
    .col-md-offset-1 {
        margin-left: 8.3333%;
    }
}

.tp-caption h1 {
    color: #fff;
    margin-bottom: 10px;
    font-weight: 500;
}

.social {
    opacity: 0.7 !important;
    transform: none !important;
}

.nominee-card .overlay .vote:not([disabled]) {
    opacity: 1 !important;
}

.nominee-card .overlay .vote {
    transform: none !important;
    transition: none !important;
}

.nominee-card .vote {
    border: 0;
    font-weight: 700;
    text-transform: uppercase;
}

#all-nominees-button:hover, #all-nominees-button:visited, #all-nominees-button:focus {
    color: unset;
}

#all-nominees-modal .modal-dialog {
    width: 470px;
    overflow-y: initial !important
}

#all-nominees-modal .modal-body {
    max-height: 85vh;
    border-radius: 6px;
    overflow-y: auto;
}

.nominee-row {
    padding: 10px 0;
}

.nominee-row a {
    font-size: 17px;
}

.nominee-row p {
    font-size: 14px;
    word-spacing: normal;
}

.nominee-row button :not([disabled]) span {
    cursor: pointer;
    line-height: 30px;
}

.nominee-thumbnail {
    width: 200px;
    height: 125px;
    margin: 0 20px;
    object-fit: cover;
    object-position: left top;
    float: left;
}

.social:hover, .social:active,
div.nominee-card:hover .social, div.nominee-card:active .social {
    opacity: 0.9 !important;
}

@media (width >= 1200px) {
    #CybotCookiebotDialogBody { /* stylelint-disable-line selector-id-pattern */
        max-width: 992px !important;
    }
}

.CookieDeclaration p { /* stylelint-disable-line selector-id-pattern */
    font-size: 18px;
}

.vimeo-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.2720%;
    background-color: black;
    margin-bottom: 15px;
}

.vimeo-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.vimeo-alt {
    top: 50%;
    color: #fff;
    text-align: center;
    font-weight: bolder;
    padding: 35px;
    position: absolute;
    transform: translateY(-50%);
    font-size: 15px;
}

input[name="youth"] {
    width: 20px;
    height: 20px;
    margin-right: 15px
}

.row-eq-height {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}

#all-nominees-modal button.vote {
    background-color: transparent;
    border: none;
    padding-left: 0;
    font-weight: 700;
}

#all-nominees-modal button.vote:hover,
#all-nominees-modal button.vote:focus {
    text-decoration: underline;
}

button.vote[disabled] {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
}