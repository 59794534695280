/*
@lightblue = rgba(32, 50, 95, 0.5);
@web awards blue = #3399CC;
eurid blue = #1d3866;
*/

/* Global Styling */
body{
    color:#666;
}



/* Helper Classes & Shorcodes */

.bglight{
    background: #f5f5f5;
}

.bgdark{
    background: #1d3866;
}

.bgdefault{
    background: #515A5F;
}

.whitecolor{
    color: #fff;
}

.darkcolor{
    color: #515A5F;
}

.colorblack{
    color: #232323;
}


/* sidebar search */
.widget_search .form-control {
    border: 2px #e8ecf0 solid;
}

.widget_search .input-group-addon {
    background: transparent;
    border: 2px #e8ecf0 solid;
    color: #1d3866;
}

.widget .media-body > a{
    color: #515A5F;
}


/* Categories */
.category li {
    border-bottom: 1px solid #e8ecf0;
}

.category li a{
    color: #666;
}

.category li a:hover, .category li a:focus,
.widget .media-body > a:hover{
    color: #1d3866;  
}


/* Tags */
ul.web_tags li a {
    background: #1d3866;
    color: #fff;
}

ul.web_tags li a:after {
    background: #fff ;
}

/* Pagination */
.pager{
    border-bottom: 2px solid #ebebeb;
}

.pager li > a {
    background: rgb(0 0 0 / 0%);
    color: #999;
}

.pager li > a:before{
    background: #1d3866;
}

.pager li > a:hover,
.pager li > a:focus,
.pager li.active > a{
    color: #1d3866;
    background: transparent;
}

/* Comment Profile */
.eny_profile {
    border-bottom: 1px solid #e4e4e4;
}

/* Social Icons */
ul.social li a{
    border:1px solid #bdbdbd;
    color:#151515;
}

ul.social li a:before{
    background:#515A5F;
}

ul.social li a:hover,
ul.social li a:focus{
	color:#fff;
}

ul.social_vertical li a{
    color: #515A5F;
}

ul.social.white li a{
    border:1px solid #fff;
}

ul.social_simple.white li a,
ul.social.white li a{
    color: #fff;
}

ul.social_simple li a:hover,
ul.social_simple li a:focus,
ul.social_vertical li a:hover,
ul.social_vertical li a:focus,
.topbar .topbar_inner span > a:hover,
.topbar .topbar_inner span > a:focus{
    color: #1d3866;
}


.back-top {
  background:#515A5F;
  color:#fff;
}

.back-top:hover, .back-top:focus{
	color:#fff;
}


/* Helper Classes ends */


/* Buttons */

.button.dark, .button.light, .button.white{
    color: #fff;
}

.button.dark{
    background: #515A5F;
}

.button.light{
    background: #39C;
}

.button.white{
    background: transparent;
    border: 2px solid #fff;
}

/* hover */
.button.light:before{
    background: #515A5F;
}

#maxo-main .button.white:hover,
#maxo-main .button.white:focus,
#maxo-main .button.light:hover,
#maxo-main .button.light:focus{
    color: #39C;
    border: 2px solid #39C;
}

.button.white:hover,
.button.white:focus{
    color: #39C;
}

.button.dark:before{
     background: #1d3866;
}

.button.dark:hover, 
.button.dark:focus{
    border: 2px solid #1d3866;
}

.button.white:before,
#maxo-main .button.light:before{
    background: #fff;
    border-radius: 2px;
}

#maxo-main .banner-container .button.white:hover,
#maxo-main .banner-container .button.white:focus,
#maxo-main .banner-container .button.light:hover,
#maxo-main .banner-container .button.light:focus{
    color: #39C;
    border:2px solid #fff;
}

.button:hover,
.button:focus{
    color: #fff;
}


/* Some Short Codes or helper classes */



/* ------ Headers Starts ------ */

header.default nav.navbar.bootsnav.navbar-fixed{
    background: transparent;
}

header.default .bootsnav.fixedmenu{
    background: #fff !important;
}

nav.navbar.bootsnav .navbar-toggle {
    color: #515A5F;
}

header.default nav.navbar.bootsnav.navbar-fixed ul.nav > li > a{
    color: #fff;
}

header.default nav.navbar.bootsnav ul.nav > li > a,
header.default .bootsnav.fixedmenu ul.nav > li > a,
header.default nav.navbar.bootsnav.navbar-fixed.fixedmenu ul.nav > li > a{
    color: #515A5F;
    background-color: transparent;
}

nav.navbar.bootsnav ul.nav > li > a:before,
.cbp-l-filters .cbp-filter-item span:after,
.overlay-menu ul.full-nav  li a:before{
	background: #1D3866;
}


/* OVERLAY Menu */
.menu-icon span,
.menu-icon span:before,
.menu-icon span:after {
  background: #515A5F;
}

header.default nav.navbar.bootsnav.navbar-fixed.fixedmenu .menu-icon span,
header.default nav.navbar.bootsnav.navbar-fixed.fixedmenu .menu-icon span:before,
header.default nav.navbar.bootsnav.navbar-fixed.fixedmenu .menu-icon span:after{
    background: #515A5F;
}

header.default nav.navbar.bootsnav.navbar-fixed .menu-icon span,
header.default nav.navbar.bootsnav.navbar-fixed .menu-icon span:before,
header.default nav.navbar.bootsnav.navbar-fixed .menu-icon span:after{
    background: #fff;
}

.overlay-menu {
  color: #515A5F;
}

.overlay-menu ul.full-nav li {
	background: #f5f5f5;
}

.overlay-menu ul.full-nav li > span {
	color: #515A5F;
}

.overlay-menu ul.full-nav  li a{
    color: #515A5F;
}


/* -----  Headers Ends ------ */



/* ------ main Slider Starts ------ */

/* Bullets
Swiper & Revotion */
.tp-bullet,
.swiper-pagination-bullet{
    background: #515A5F;
}

.tp-bullet:hover,
.tp-bullet.selected,
.swiper-pagination-bullet:hover,
.swiper-pagination-bullet-active{
    background: #1d3866;
}

/* ------ main Slider ends ------ */


/* ------ Swiper slider ------ */


/* Testimonial & Latest News */
.testimonial .testimonial_slide .photo {
    border: 3px solid #515A5F;
}

.ivydatedate{
    color: #515A5F;
}

.ivydatedate:hover, .ivydatedate:focus{
    color: #fff;
    background: #1d3866;
}

.updates .latest_updates .update_text h6:hover a,
.updates .latest_updates .update_text h6:focus a{
    color: #515A5F;
}

/* Tweets */
.tweet_box .twee_inner p > a{
    color: #515A5F;
}

.tweet_box .twittername:hover,
.tweet_box .twee_inner p > a:hover{
    color: #1d3866;
}

/* ------ Swiper Slider ends ------ */


/* ------ POrtfolio starts ------ */
.overlay,
.overlay .likeus,
.overlay .social {
    background: #1d3866;
}


/* Filters */
.cbp-l-filters .cbp-filter-item span {
    color: #515A5F;
}

.cbp-item .text_wrap{
    color: #202020;
}

.cbp-item .text_wrap p{
    color: transparent;
}

/* PopUp */
.fancybox-thumbs > ul > li:before {
  border: 4px solid #515A5F;
}

/* ------ Portfolio ends ------ */



/* ------ Page Header starts ------ */

.breadcrumb{
    background: transparent;
}

.breadcrumb li,
.breadcrumb li a{
    color: #fff;
}

.breadcrumb li a:hover{
    color: #1d3866;
}

/* ------ Page Header ends ------ */


/* ------ Parallax  & Bg starts ------ */
.parallax h2 i{
    color: #9c9c9c;
}

.parallax.parallax-bg p{
    color: #1d3866;
}

/* ------ Parallax starts ------ */



/* ------ Features ------ */

.icon_wrap .icon_box i {
    color: #1d3866;
}

.icon_wrap .icon_box {
    color: #666;
    background: #fff;
}

.icon_wrap .icon_box:hover i,
.icon_wrap .icon_box:hover{
    color: #fff;
}

.icon_wrap .icon_box:hover{
    background: #1d3866;
}

/* ------ Features ends ------ */


/* ------ Our Team ------ */
.ourteam .team_caption {
    background: #f5f5f5;
}

.team_caption .overlay {
    background: #1d3866; 
}

.ourteam .social_vertical {
    background: #fff;
}

/* ------ Our Team ------ */


/* ------ Our Blog ------ */

.ourblog:hover h3 a,
.readmore, .blog_item:hover h3 a{
    color: #1d3866;
}

.blog_slider .blog_inner {
    background: rgb(66 217 288 / 90%);
    color: #fff;
}

.blog_author.borderleft .metas > i,
.blog_author.borderleft .authorname a,
.blog_author.flat .metas > i,
.blog_author.flat .authorname a{
    color: #515A5F;
}

.postanchor{
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    position: relative;
}

.postanchor .previous > a,
.postanchor .nextpost > a{
    color: #515A5F;
}

.postanchor .share {
    color: #fff;
}

.share_purpose, .share_purpose:before {
    background: #1d3866;
}

.share_purpose .social_simple li a{
    border-left: 1px solid #62d3db;
}

.share_purpose .social_simple li a:hover{
    color: #515A5F;
}

/* ------ Our Blog ------ */


/* ------ Pricings ------ */

.pricing_item.active{
    background: #1d3866;
    color: #fff;
}

.pricing_item.active p,
.pricing_item.active .darkcolor{
    color: #fff;
}

.pricing_item .pricebox{
    border-bottom: 1px solid #e6e1e1;
}

.pricebox .price_title .ratings i{
    color: #1d3866;
}

.pricing_item  .availability:before{
    color: #515A5F;
}

.pricing_item.active  .availability:before{
    color: #fff;
}

.pricing_item.active .button.light{
    background: #fff;
    color: #515A5F;
}

.pricing_item.active .button.light:hover, 
.pricing_item.active .button.light:focus {
    color: #fff;
    border-color: #fff;
}

.pricing_item.active .button.light:before{
    background: #1d3866;
}

/* ------ Pricings ends ------ */



/* ------ Contact Us starts ------ */

/* ------ Contact Us ends ------ */


@media screen and (width <= 992px) {
    /* Header */
    header.default nav.navbar.bootsnav.navbar-fixed{
        background: #fff;
    }

    nav.navbar.bootsnav ul.nav > li{
        margin: 0 10px;
    }

    header.default nav.navbar.bootsnav.navbar-fixed ul.nav > li > a{
        color: #515A5F;
    }

    .menu-icon span, .menu-icon span:before, .menu-icon span:after,
    header.default nav.navbar.bootsnav.navbar-fixed.fixedmenu .menu-icon span,
    header.default nav.navbar.bootsnav.navbar-fixed.fixedmenu .menu-icon span:before,
    header.default nav.navbar.bootsnav.navbar-fixed.fixedmenu .menu-icon span:after,
    header.default nav.navbar.bootsnav.navbar-fixed .menu-icon span,
    header.default nav.navbar.bootsnav.navbar-fixed .menu-icon span:before,
    header.default nav.navbar.bootsnav.navbar-fixed .menu-icon span:after{
        background: #1d3866;
    }
    
}
